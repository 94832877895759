import type { LoaderFunctionArgs, MetaDescriptor, MetaFunction } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";

import { Routes } from "~/constants/routes";
import { getExchangeRates } from "~/modules/currency/index.server";
import { HandleSchema } from "~/modules/wisher";
import {
  composeWishlistData,
  getCoverImage,
  transformNotices,
  useWishlistData,
  WishlistPageOwner,
  WishlistPagePublic,
} from "~/modules/wishlist";
import { redirectOldRoutes } from "~/modules/wishlist/index.server";
import type { Currency, Wisher, Wishlist } from "~/types";
import { getWishlistItemOGImage, getWishlistOGImage } from "~/utils/dynamic-image.server";
import { createWtf } from "~/utils/fetch.server";

// TODO: refactor this loader to decrease complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
export async function loader({ request, params }: LoaderFunctionArgs) {
  if (!params.handle || !HandleSchema.safeParse(params.handle).success) {
    throw new Response("User profile not found.", { status: 404 });
  }
  const shouldRedirect = redirectOldRoutes({ request, params });
  if (shouldRedirect) return shouldRedirect;

  try {
    const wtf = createWtf(request);
    const query = new URLSearchParams({
      handle_lowercased: params.handle?.toLowerCase() ?? "",
    });
    const response = await wtf(`/api/aliases?${query.toString()}`);
    if (response.status === 302) {
      return redirect(Routes.public.gifter({ username: params.handle }));
    } else if (response.status !== 200) {
      throw new Response("User profile not found.", { status: 404 });
    }
    const data = await response.json();
    const wishlist = data.wishlists[0];
    if (!wishlist) throw new Response("User profile not found.", { status: 404 });

    const authData = await wtf("/api/current/auth", {}, { json: true });

    let notices = [];
    if (authData.auth?.wisher && authData.auth?.wisher.noticesSeen.length === 0) {
      const noticesReponse = await wtf("/api/notifications/notices");
      if (noticesReponse.status === 200) {
        notices = await noticesReponse.json();
      }
    }

    const userCurrency = authData.currency;
    const wisherCurrency: Currency = data.displayCurrency ?? data.currency;
    const gifterCurrency: Currency =
      !userCurrency || userCurrency === "noConversion" ? wisherCurrency : userCurrency;
    const exchangeRates =
      gifterCurrency === wisherCurrency && gifterCurrency === "USD"
        ? { rates: { USD: 1 } as Record<Currency, number> }
        : await getExchangeRates(wtf, gifterCurrency);
    const gifterToWisherRate = exchangeRates.rates[wisherCurrency];
    const wisherToGifterRate = gifterToWisherRate ? 1 / gifterToWisherRate : 1;

    const loaderData = {
      wisher: {
        id: data._id,
        activated: data.activated,
        activatedStatus: data.activatedStatus,
        canActivate: data.canActivate,
        frozen: data.frozen,
        frozenReason: data.frozenReason,
        handle: data.handle,
        name: data.aliasName,
        avatarUrl: data.cdnProfileImage || data.profileImage,
        bio: wishlist.wishlistMessage,
        coverUrl: getCoverImage(wishlist),
        currency: wisherCurrency,
        tags: data.tags || [],
        socialLinks: data.socialLinks || [],
      } as Wisher,
      wishlist: composeWishlistData({
        wishlist,
        gifterCurrency,
        wisherCurrency,
        exchangeRate: wisherToGifterRate,
      }) as Wishlist,
      gifterToWisherRate,
      notices: transformNotices(notices),
    };

    const twitterOGImage = getTwitterOGImage({
      wisher: loaderData.wisher,
      wishlist: loaderData.wishlist,
      itemId: new URL(request.url).searchParams.get("item") as string,
    });

    return json({
      ...loaderData,
      meta: {
        twitterOGImage,
      },
    });
  } catch (error) {
    if (error instanceof Response) throw error;
    throw new Response(error instanceof Error ? error.message : "Unknown error");
  }
}

export default function WishlistRoute() {
  const { isWishlistOwner } = useWishlistData();

  return isWishlistOwner ? <WishlistPageOwner /> : <WishlistPagePublic />;
}

// eslint-disable-next-line func-style
export const meta: MetaFunction<typeof loader> = ({ data }): MetaDescriptor[] => {
  const wisherName = data?.wisher?.name || "Wisher";
  const twitterOGImage = data?.meta.twitterOGImage;

  return [
    {
      title: "WishTender",
    },
    {
      name: "description",
      content: "Universal wishlist. Receive and give gifts safely and easily.",
    },
    {
      property: "twitter:card",
      content: "summary_large_image", // Use for large image
    },
    {
      property: "twitter:site",
      content: "@wishtender",
    },
    {
      property: "twitter:title",
      content: `${wisherName}'s Wishlist`,
    },
    {
      property: "twitter:description",
      content: "Receive or give gifts safely and easily.",
    },
    {
      property: "twitter:image",
      content: twitterOGImage,
    },
  ];
};

function getTwitterOGImage({
  wisher,
  wishlist,
  itemId,
}: {
  wisher?: Wisher;
  wishlist?: Wishlist;
  itemId?: string;
}) {
  // get dynamic image for wisher
  const wishlistItem = wishlist?.items?.find((wishlistItem) => wishlistItem.id === itemId);
  if (wishlistItem) {
    return getWishlistItemOGImage(wishlistItem);
  }

  if (wisher) {
    return getWishlistOGImage(wisher);
  }

  return "https://www.wishtender.com/images/twitter_card.png";
}
